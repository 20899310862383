import { keyframes } from 'styled-components'

export const slideinFromLeft = keyframes`
  from {
    transform: translateX(-1rem);
    opacity: 0;
  }
  to {
    transform: none;
    opacity: 1;
  }
`
export const slideinFromRight = keyframes`
  from {
    transform: translateX(1rem);
    opacity: 0;
  }
  to {
    transform: none;
    opacity: 1;
  }
`
export const slideinFromTop = keyframes`
  from {
    transform: translateY(-1rem);
    opacity: 0;
  }
  to {
    transform: none;
    opacity: 1;
  }
`
export const slideinFromBottom = keyframes`
  from {
    transform: translateY(1rem);
    opacity: 0;
  }
  to {
    transform: none;
    opacity: 1;
  }
`
export const zoomSlideinFromBottom = keyframes`
  from {
    transform: translateY(1rem) scale(0.8);
    opacity: 0;
  }
  to {
    transform: none;
    opacity: 1;
  }
`