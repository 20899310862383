import React from 'react'
import { mount } from '../utils/mount'
import { TopFaqSection } from '../sections/top-faq-section'
import { ll } from '../utils/lcqw'
import { styled } from 'styled-components'

const Outer = styled.div`
  background-color: #fff;
  border-radius: ${ll(8)};
`

mount('#theme-faq', <Outer><TopFaqSection hideContact /></Outer>)