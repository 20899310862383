import React, { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import { ll } from '../utils/lcqw'

const Outer = styled.div`
  padding-top: ${ll(153)};
  padding-bottom: ${ll(55)};
  @media (max-width: 40rem){
      padding-top: ${ll(90)};
      padding-bottom: ${ll(33)};
  }
`

const Inner = styled.div`
  position: relative;
`

const Line = styled.div`
  &::before,
  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 1px;
    height: 95%;
    top: 2.5%;
    background-color: currentColor;
  }
  &::before {
    left: ${ll(-50)};
    transform: rotate(-30deg);
  }
  &::after {
    right: ${ll(-50)};
    transform: rotate(30deg);
  }
`

const Label = styled.div`
font-size: ${ll(100)};
line-height: normal;
letter-spacing: 0.08em;
font-weight: 800;
font-family: var(--en-font);
text-align: center;
position: absolute;
top: ${ll(-80)};
left: 50%;
color: #EEE0BD;
opacity: 0.5;
white-space: nowrap;
transform: translateX(-50%);
@media (max-width: 40rem){
  font-size: ${ll(60)};
line-height: normal;
letter-spacing: 0.08em;
font-weight: 800;
top: ${ll(-50)};
}
`

const Title = styled.div`
font-size: ${ll(36)};
line-height: normal;
letter-spacing: 0.08em;
font-weight: 700;
position: relative;
z-index: 1;
text-align: center;
@media (max-width: 40rem){
  font-size: ${ll(24)};
line-height: normal;
letter-spacing: 0.08em;
font-weight: 700;
}
strong {
  color: var(--green);
  strong {
    font-size: ${ll(50)};
    line-height: 1.1200000047683716;
    letter-spacing: 0.08em;
    font-weight: 700;
    @media (max-width: 40rem){
      font-size: ${ll(26.52510643005371)};
      line-height: 1.1200000047683716;
      letter-spacing: 0.08em;
      font-weight: 700;
    }
  }
}
`

const Border = styled.div`
  position: absolute;
  bottom: ${ll(-4)};
  width: 100%;
  left: 0;
  border-bottom: ${ll(4)} dotted var(--green);
`

type SectionHeadProps = {
  label: string
  line?: boolean
  border?: boolean
  children?: ReactNode
}
export const SectionHead: FunctionComponent<SectionHeadProps> = ({label, line, children, border}) => {
  return <Outer>
    <Inner>
      {
        line && <Line />
      }
      <Label>{label}</Label>
      <Title>
        {children}
      </Title>
      {
        border && <Border />
      }
    </Inner>
  </Outer>
}